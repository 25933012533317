import { Box, Container, IconButton, InputAdornment, Slide, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { forwardRef, useEffect, useState } from 'react';
import { useFetch } from '../../hooks/useFetch';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import lodash from 'lodash';
import { endOfMonth, startOfMonth } from 'date-fns';
import styled from 'styled-components';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default props => {
    const [filterOpened, setFilterOpened] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    const { handleChange, handleSubmit, values, setValues, setFieldValue } = useFormik({
        initialValues: {
            createdAt: '',
        },
        onSubmit: values => {
            setSearchParams(lodash.omitBy(values, v => v === ''));
        },
    });

    useEffect(() => {
        if (searchParams) {
            let toFields = Object.fromEntries(searchParams);
            toFields.createdAt = searchParams.getAll('createdAt') || undefined;
            setValues(toFields, false);
        }
    }, [searchParams]);

    const { data, error, mutate, isFetching } = useFetch('/report/dashboard');

    const PickerDate = ({ openCalendar, value, separator, ...props }) => {
        value = value.split(separator);

        const viewValue = !value
            ? ''
            : value.length === 1
            ? new Date(value[0]).toLocaleDateString()
            : value.length === 2
            ? `${new Date(value[0]).toLocaleDateString()} a ${new Date(value[1]).toLocaleDateString()}`
            : '';

        return (
            <TextField
                placeholder="Selecione uma ou mais datas"
                fullWidth
                value={viewValue}
                label="Data de Embarque"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconButton onClick={openCalendar}>
                                <CalendarIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <>
                            <InputAdornment position="end" sx={{ display: Boolean(values.departure_date) ? 'inherit' : 'none' }}>
                                <IconButton onClick={() => setFieldValue('departure_date', '')}>
                                    <CloseIcon />
                                </IconButton>
                            </InputAdornment>
                        </>
                    ),
                }}
            />
        );
    };

    return (
        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h5" component="h2" my={3}>
                        Dashboard
                    </Typography>
                    {/* <IconButton color="primary" size="large" variant="contained" onClick={e => setFilterOpened(true)}>
                        <SearchIcon fontSize="inherit" />
                    </IconButton> */}
                </Stack>

                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box
                            sx={{
                                background: '#f9eac3',
                                borderRadius: 2,
                                minHeight: 150,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                paddingHeight: 20,
                            }}
                        >
                            {' '}
                            <Counter>{data?.DRAFT || 0}</Counter> <SubCounter>Vendas em aberto</SubCounter>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box
                            sx={{
                                background: '#f1e8cd',
                                borderRadius: 2,
                                minHeight: 150,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                paddingHeight: 20,
                            }}
                        >
                            {' '}
                            <Counter>{data?.CLOSED || 0}</Counter> <SubCounter>Vendas fechadas</SubCounter>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box
                            sx={{
                                background: '#e8e7d7',
                                borderRadius: 2,
                                minHeight: 150,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                paddingHeight: 20,
                            }}
                        >
                            {' '}
                            <Counter>{data?.CANCELED || 0}</Counter> <SubCounter>Vendas canceladas</SubCounter>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box
                            sx={{
                                background: '#dee5e1',
                                borderRadius: 2,
                                minHeight: 150,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                paddingHeight: 20,
                            }}
                        >
                            {' '}
                            <Counter>{data?.WAITING || 0}</Counter> <SubCounter>Pedidos aguardando</SubCounter>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box
                            sx={{
                                background: '#d4e3eb',
                                borderRadius: 2,
                                minHeight: 150,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                paddingHeight: 20,
                            }}
                        >
                            {' '}
                            <Counter>{data?.ORDER_CANCELED || 0}</Counter> <SubCounter>Pedidos cancelados</SubCounter>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box
                            sx={{
                                background: '#c9e2f5',
                                borderRadius: 2,
                                minHeight: 150,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                paddingHeight: 20,
                            }}
                        >
                            {' '}
                            <Counter>{data?.IN_PROGRESS || 0}</Counter> <SubCounter>Pedidos em produção</SubCounter>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box
                            sx={{
                                background: '#c9e2f5',
                                borderRadius: 2,
                                minHeight: 150,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                paddingHeight: 20,
                            }}
                        >
                            {' '}
                            <Counter>{data?.FINISHED || 0}</Counter> <SubCounter>Pedidos finalizados</SubCounter>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Box
                            sx={{
                                background: '#bde0ff',
                                borderRadius: 2,
                                minHeight: 150,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                paddingHeight: 20,
                            }}
                        >
                            <Counter>{data?.CONCLUDED || 0}</Counter> <SubCounter>Pedidos entregues</SubCounter>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

const Counter = styled.p`
    font-size: 60px;
    font-weight: bold;
    display: inline-block;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
        sans-serif;
`;
const SubCounter = styled.p`
    font-size: 16px;
    display: inline-block;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
        sans-serif;
`;
