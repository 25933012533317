import useSWR from 'swr';
import server from '../global/server';
import { useApp } from '../context/AppContext';

export function useFetch(url, params, blocked = false) {
    const { setLoading } = useApp();
    const { data, error, mutate } = useSWR(
        { url, params },
        async ({ url, params }) => {
            setLoading(true);
            const { data } = await server.get(url, params);
            setLoading(false);
            return data;
        },
        {
            errorRetryCount: 1,
        },
    );

    return { data: data?.data, error, isLoading: !error && !data, mutate };
}
