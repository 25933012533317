import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import lodash from 'lodash';

export default ({}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { values, handleChange, setValues, handleSubmit, handleReset } = useFormik({
        initialValues: {
            product_name: '',
        },
        onSubmit: async (values, {}) => {
            try {
                setSearchParams({ ...lodash.omitBy(values, v => v === '') }); //, sales_status: values.sales_status, order_status: values.order_status
            } catch (error) {
                console.log(error);
            }
        },
    });

    useEffect(() => {
        if (Boolean(searchParams)) {
            let toFields = Object.fromEntries(searchParams);
            toFields.sale_at = searchParams.getAll('sale_at') || undefined;
            toFields.delivery_date = searchParams.getAll('delivery_date') || undefined;
            setValues(toFields, false);
        }
    }, [searchParams]);

    return (
        <TextField
            fullWidth
            label="Buscar produtos"
            name="product_name"
            onChange={handleChange}
            value={values.product_name}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {Boolean(searchParams.size) ? (
                            <IconButton
                                onClick={e => {
                                    setSearchParams();
                                    handleReset();
                                }}
                                edge="end"
                            >
                                <CloseIcon />
                            </IconButton>
                        ) : (
                            <IconButton onClick={handleSubmit} edge="end">
                                <SearchIcon />
                            </IconButton>
                        )}
                    </InputAdornment>
                ),
            }}
        />
    );
};
