import axios from 'axios';
const SERVER = process.env.NODE_ENV === 'development'? process.env.REACT_APP_LOCALHOST_SERVER:  process.env.REACT_APP_ENVIRONMENT === 'develop'? process.env.REACT_APP_DEV_SERVER: process.env.REACT_APP_PROD_SERVER

const server = axios.create({
    baseURL: SERVER,
    timeout: 5000,
    //timeoutErrorMessage: 'Servidor Offline. Tente mais tarde.'
});

server.interceptors.response.use(
    response => response,
    error => {
        throw error;
    },
);

export default server;
