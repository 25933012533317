import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import SelectCategory from '../../../components/SelectCategory';
import SelectCustomer from '../../../components/SelectCustomer';

export default ({ handleChange, setFieldValue, values, errors }) => {
    return (
        <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
                <SelectCustomer
                    required
                    helperText={errors.client_id}
                    onChange={setFieldValue}
                    value={values.client_id}
                    fullWidth
                    name="client_id"
                    label="Cliente"
                />
            </Grid>
            {Boolean(values.id) && (
                <>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel id="selec-payment_mode">Método de pagamento</InputLabel>
                            <Select
                                labelId="selec-payment_mode"
                                id="payment_mode"
                                value={values.payment_mode}
                                label="Modo de venda"
                                name="payment_mode"
                                onChange={handleChange}
                            >
                                <MenuItem value="credit_card">Cartão de crédito - A vista</MenuItem>
                                <MenuItem value="credit_card_installments">Cartão de crédito - Parcelado</MenuItem>
                                <MenuItem value="debit_card">Cartão de débito</MenuItem>
                                <MenuItem value="cash">Dinheiro</MenuItem>
                                <MenuItem value="pix">Pix</MenuItem>
                            </Select>
                            <FormHelperText>{errors.payment_mode}</FormHelperText>
                        </FormControl>
                    </Grid>
                     
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            helperText={errors.discount}
                            onChange={handleChange}
                            value={values.discount}
                            fullWidth
                            name="discount"
                            label="Desconto (%)"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            helperText={errors.total}
                            InputProps={{readOnly: true}}
                            value={Number(values.total).toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            fullWidth
                            label="Total da Venda"
                        />
                    </Grid>
                </>
            )}
        </Grid>
    );
};
