import { Button, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, Tab, Tabs } from '@mui/material';
import styled from 'styled-components';
import SalesProducts from './SalesProducts';
import { LoadingButton } from '@mui/lab';
import SalesClose from './SalesClose';
import { useFormik } from 'formik';
import server from '../../../global/server';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import useHandleErrors from '../../../hooks/useHandleErrors';
import { useFetch } from '../../../hooks/useFetch';
import SalesForm from './SalesForm';
import SalesOrder from './SalesOrder';
import { Close } from '@mui/icons-material';

export default ({ setViewDialog, setDialogNewOrder, mutate }) => {
    const [errs, setErrs] = useState();
    const { enqueueSnackbar } = useSnackbar();
    useHandleErrors(errs);

    const { handleChange, handleSubmit, handleReset, setValues, setFieldValue, values, errors, isSubmitting } = useFormik({
        initialValues: {
            client_id: '',
            payment_mode: 'cash'
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true);
                values.discount = Number(values.discount);
                const { data } = await server.post('/order', values);
                await mutate();
                enqueueSnackbar('Venda iniciada');
                setViewDialog(data.data.id);
                setDialogNewOrder(false);
                setSubmitting(false);
            } catch (error) {
                setSubmitting(false);
                setErrs(error);
            }
        },
    });

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <DialogTitle>{Boolean(values.id) ? `Venda #${values.id}` : 'Nova venda'}</DialogTitle>
                <div>
                    <IconButton
                        onClick={e => {
                            setViewDialog();
                            handleReset();
                        }}
                    >
                        <Close />
                    </IconButton>
                </div>
            </Stack>
            <Divider />
            <DialogContent>
                <SalesForm values={values} handleChange={handleChange} setFieldValue={setFieldValue} errors={errors} />
            </DialogContent>
            <DialogActions>
                <LoadingButton onClick={handleSubmit}>Salvar</LoadingButton>
            </DialogActions>
        </>
    );
};

const TabPanel = styled.div`
    display: ${props => (props.active ? 'block' : 'none')};
`;
