import { Box, Container, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import { useFetch } from '../../../hooks/useFetch';
import PointsList from '../components/PointsList';
import Productslist from '../components/ProductsList';
import TabPanel from '../components/TabPanel';
import UsersList from '../components/UsersList';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Settings() {
    // const { data, error, mutate, isFetching } = useFetch('/config');
    const [value, handleChange] = useState(0);

    return (
        <Container fixed>
            <Typography variant="h5" component="h2" my={3}>
                Configurações
            </Typography>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={(e, index) => handleChange(index)}>
                    <Tab label="Usuários" {...a11yProps(0)} />
                    <Tab label="Produtos" {...a11yProps(1)} />
                    <Tab label="Portos" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <UsersList />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Productslist />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <PointsList />
            </TabPanel>
        </Container>
    );
}
