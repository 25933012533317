import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { DataGrid } from '@mui/x-data-grid';
import { useMemo, useState } from 'react';
import LoadingContent from '../../../components/LoadingContent';
import { useApp } from '../../../context/AppContext';
import { useFetch } from '../../../hooks/useFetch';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import ProfileForm from '../components/ProfileForm';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

export default props => {
    const [addNew, setAddNew] = useState(false);
    const [editUser, setEditUser] = useState();

    const { data, error, mutate, isFetching } = useFetch('/user');

    const columns = useMemo(() => [
        {
            field: 'id',
            headerName: 'ID',
            width: 70,
        },
        {
            field: 'name',
            headerName: 'Nome',
            minWidth: 300,
            flex: 1,
        },
        {
            field: 'document',
            headerName: 'Login',
            width: 200,
        },

        {
            field: 'actions',
            type: 'actions',
            renderCell: ({ row }) => (
                <Button
                    variant="outlined"
                    onClick={() => {
                        setEditUser(row);
                        setAddNew(true);
                    }}
                    size="small"
                    style={{ fontSize: 10 }}
                >
                    Detalhes
                </Button>
            ),
        },
    ]);
    return (
        <>
            <Container fixed>
                <Typography variant="h5" component="h2" my={3}>
                    Usuários
                </Typography>
                <Grid container sx={{ marginBottom: 2 }}>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                        <Button variant="contained" onClick={e => setAddNew(true)}>
                            Adicionar novo
                        </Button>
                    </Grid>
                </Grid>

                <Grid container sx={{ height: 500 }}>
                    <DataGrid paginationMode="server" loading={isFetching} autoPageSize rows={data || []} columns={columns} />
                </Grid>
            </Container>
            <Dialog fullWidth maxWidth="sm" open={addNew} scroll="paper" onClose={e => setAddNew(false)}>
                <DialogTitle>
                    Dados do usuário
                    <IconButton
                        aria-label="close"
                        onClick={e => setAddNew(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <ProfileForm mutate={mutate} data={editUser} />
            </Dialog>
        </>
    );
};
