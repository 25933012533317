import { Button, Divider, Stack, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useMemo } from 'react';
import { useFetch } from '../../../hooks/useFetch';
import SelectProduct from '../../../components/SelectProduct';

export default ({ setFieldValue, handleChange, selectedProduct, isBoard, isChild, values }) => {
    // const { data, mutate, error, isFetching } = useFetch('/product');

    // const columns = useMemo(() => [
    //     { field: 'id', headerName: 'ID', width: 50 },
    //     {
    //         field: 'product_name',
    //         headerName: 'Nome',
    //         flex: 1,
    //         minWidth: 250,
    //         // valueGetter: params => size(params.value)
    //     },
    //     {
    //         field: 'bleed',
    //         headerName: 'Sangria',
    //         flex: 1,
    //         // valueGetter: params => size(params.value)
    //     },
    //     {
    //         field: 'sale_mode',
    //         headerName: 'Modo de venda',
    //         flex: 1,
    //         // valueGetter: params => size(params.value)
    //     },
    //     {
    //         field: 'cost_price',
    //         headerName: 'Preço de custo',
    //         flex: 1,
    //         // valueGetter: params => size(params.value)
    //     },
    //     {
    //         field: 'sale_price',
    //         headerName: 'Preço de venda',
    //         flex: 1,
    //         // valueGetter: params => size(params.value)
    //     },
    //     {
    //         field: 'Category',
    //         headerName: 'Categoria',
    //         flex: 1,
    //         valueGetter: ({ value }) => value.name,
    //         renderCell: ({ value }) => value,
    //     },
    //     {
    //         field: 'stock',
    //         headerName: 'Estoque',
    //         flex: 1,
    //         // valueGetter: params => size(params.value)
    //     },

    //     // {
    //     //     field: 'status',
    //     //     headerName: 'Status',
    //     //     renderCell: params => (params.value ? <Chip color="success" label="Ativo" /> : <Chip color="error" label="Inativo" />),
    //     // },

    //     {
    //         field: 'actions',
    //         //headerName: 'actions',
    //         type: 'actions',
    //         renderCell: ({ row }) => (
    //             <Button
    //                 onClick={e => {
    //                     selectedProduct(row);
    //                 }}
    //             >
    //                 Adicionar
    //             </Button>
    //         ),
    //     },
    // ]);

    const handleSelectProduct = item => {
        setFieldValue('product_id', item.id);
        setFieldValue('board', item.board);
        setFieldValue('frame', item.frame);
        setFieldValue('printed', item.printed);
    };

    return (
        <>
            {/* <DataGrid autoHeight loading={isFetching} rows={data || []} columns={columns} /> */}
            
            <Stack spacing={2}>
                <SelectProduct onChange={handleSelectProduct} sale value={values.product_id} exclude={isChild} />
                {values.board && <TextField variant="outlined" onChange={handleChange} label="Quantidade" name="qty" value={values.qty} />}

                {isBoard && (
                    <>
                        <TextField variant="outlined" onChange={handleChange} label="Largura" name="width" value={values.width} />
                        <TextField variant="outlined" onChange={handleChange} label="Altura" name="height" value={values.height} />
                    </>
                )}
            </Stack>
        </>
    );
};
