import { Button, DialogActions, DialogContent, Divider, FormHelperText, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import useHandleErrors from '../../../hooks/useHandleErrors';
import server from '../../../global/server';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

export default ({ data }) => {

    const [errs, setErrs] = useState()
    useHandleErrors(errs)
   
    const { enqueueSnackbar } = useSnackbar();
    const {values, isSubmitting, handleChange, handleSubmit} = useFormik({
        initialValues: {
            stock: '',
        },
        onSubmit: async (values, {setSubmitting})=>{
            try{
                setSubmitting(true)
                await server.post('product/stock', {
                    product_id: data.id,
                    stock: values.stock
                })
                enqueueSnackbar('Estoque atualizado');
                setSubmitting(false)
            }catch(error){
                setSubmitting(false)
                setErrs(error)
            }
        }
    })
    return (
        <>
            <Divider />
            <DialogContent>
                <TextField fullWidth label="Quantidade" name="stock" value={values.stock} onChange={handleChange} />
                <FormHelperText>Informe a quantidade informada na nota fiscal ou na embalagem</FormHelperText>
            </DialogContent>
            <Divider />
            <DialogActions>
                <LoadingButton loading={isSubmitting} onClick={handleSubmit} disabled={!Boolean(values.stock)}>Salvar</LoadingButton>
            </DialogActions>
        </>
    );
};
