import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
    Box,
    Button,
    Chip,
    Container,
    Dialog,
    Divider,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useFormik } from 'formik';
import { useFetch } from '../../../hooks/useFetch';
import SaleDialog from '../components/SaleDialog';
import { orderStatus, payments, saleStatus } from '../../../global/helpers';
import CloseIcon from '@mui/icons-material/Close';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import gregorian_pt from '../../../global/gregorian_pt';
import DatePicker from 'react-multi-date-picker';
import lodash from 'lodash';
import SelectCustomer from '../../../components/SelectCustomer';
import NewOrder from '../components/NewOrder';

export default () => {
    const [filterView, setFilterView] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const { handleChange, handleSubmit, values, setValues, setFieldValue } = useFormik({
        initialValues: {
            sales_status: '',
            order_status: '',
            client_id: '',
            product_id: '',
            payment_mode: '',
            delivery_date: '',
            category_id: '',
            sale_at: '',
        },
        onSubmit: values => {
            setFilterView(false);
            setSearchParams({ ...lodash.omitBy(values, v => v === '') }); //, sales_status: values.sales_status, order_status: values.order_status
        },
    });

    const PickerDate = ({ openCalendar, value, separator, name, label, ...props }) => {
        value = value.split(separator);

        const viewValue = !Boolean(value[0])
            ? ''
            : value.length === 1
            ? new Date(value[0]).toLocaleDateString()
            : value.length === 2
            ? `De ${new Date(value[0]).toLocaleDateString()} a ${new Date(value[1]).toLocaleDateString()}`
            : '';

        return (
            <TextField
                placeholder="Selecione uma ou mais datas"
                fullWidth
                value={viewValue}
                label={label}
                InputProps={{
                    endAdornment: (
                        <>
                            <InputAdornment position="end" sx={{ display: Boolean(viewValue) ? 'inherit' : 'none' }}>
                                <IconButton onClick={() => setFieldValue(name, '')}>
                                    <CloseIcon />
                                </IconButton>
                            </InputAdornment>
                            <InputAdornment position="start">
                                <IconButton onClick={openCalendar}>
                                    <CalendarIcon />
                                </IconButton>
                            </InputAdornment>
                        </>
                    ),
                }}
            />
        );
    };
    useEffect(() => {
        if (Boolean(searchParams)) {
            let toFields = Object.fromEntries(searchParams);
            toFields.sale_at = searchParams.getAll('sale_at') || undefined;
            toFields.delivery_date = searchParams.getAll('delivery_date') || undefined;
            setValues(toFields, false);
        }
    }, [searchParams]);
    const { data, mutate, error, isLoading } = useFetch('/order', { params: searchParams });

    const [viewDialog, setViewDialog] = useState(null);
    const [dialogNewOrder, setDialogNewOrder] = useState(null);

    const columns = useMemo(() => [
        { field: 'id', headerName: 'ID', width: 50 },

        {
            field: 'Client',
            headerName: 'Cliente',
            flex: 1,
            minWidth: 150,
            valueGetter: ({ value }) => value.name,
            renderCell: ({ value }) => value,
        },
        {
            field: 'total',
            headerName: 'Total',
            flex: 1,
            valueGetter: ({ value }) => Number(value),
            renderCell: ({ value }) => value.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
        },
        {
            field: 'payment_mode',
            headerName: 'Pagamento',
            flex: 1,
            valueGetter: ({ value }) => payments[value],
        },
        {
            field: 'sales_status',
            headerName: 'Status da venda',
            flex: 1,
            valueGetter: ({ value }) => saleStatus[value],
        },
        {
            field: 'actions',
            type: 'actions',
            renderCell: ({ row }) => (
                <Button
                    onClick={e => {
                        setViewDialog(row.id);
                    }}
                >
                    Alterar
                </Button>
            ),
        },
    ]);

    const [tabActive, setTabActive] = useState(0);

    return (
        <>
            <Drawer anchor="right" variant="temporary" open={filterView} onClose={e => setFilterView(false)}>
                <Toolbar>
                    <Typography variant="h6">Filtros</Typography>
                    <Divider />
                </Toolbar>
                <Box sx={{ width: 350, p: 3 }}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item xs>
                            <FormControl fullWidth>
                                <InputLabel id="edducation">Status da Venda</InputLabel>
                                <Select
                                    labelId="edducation"
                                    id="sales_status"
                                    name="sales_status"
                                    value={values.sales_status}
                                    label="Status da Venda"
                                    onChange={handleChange}
                                >
                                    <MenuItem value="">TODOS</MenuItem>
                                    <MenuItem value="DRAFT">{saleStatus['DRAFT']}</MenuItem>
                                    <MenuItem value="CLOSED">{saleStatus['CLOSED']}</MenuItem>
                                    <MenuItem value="CANCELED">{saleStatus['CANCELED']}</MenuItem>
                                    {/* <MenuItem value="WAITING">{saleStatus['WAITING']}</MenuItem>
                                    <MenuItem value="IN_PROGRESS">{saleStatus['IN_PROGRESS']}</MenuItem>
                                    <MenuItem value="CONCLUDED">{saleStatus['CONCLUDED']}</MenuItem>
                                    <MenuItem value="PAYMENT_FAIL">{saleStatus['PAYMENT_FAIL']}</MenuItem>
                                    <MenuItem value="DISAPPROVED">{saleStatus['DISAPPROVED']}</MenuItem> */}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl fullWidth>
                                <InputLabel id="edducation">Status do Pedido</InputLabel>
                                <Select
                                    labelId="edducation"
                                    id="order_status"
                                    name="sales_status"
                                    value={values.order_status}
                                    label="Status do Pedido"
                                    onChange={handleChange}
                                >
                                    <MenuItem value="">TODOS</MenuItem>

                                    <MenuItem value="WAITING">{orderStatus['WAITING']}</MenuItem>
                                    <MenuItem value="IN_PROGRESS">{orderStatus['IN_PROGRESS']}</MenuItem>
                                    <MenuItem value="FINISHED">{orderStatus['FINISHED']}</MenuItem>
                                    <MenuItem value="CONCLUDED">{orderStatus['CONCLUDED']}</MenuItem>
                                    <MenuItem value="CANCELED">{orderStatus['CANCELED']}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            {/* <TextField fullWidth type="date" name="scheduled" value={values.scheduled} onChange={handleChange} /> */}
                            <DatePicker
                                containerStyle={{ width: '100%' }}
                                onChange={e => setFieldValue('sale_at', e)}
                                type="date"
                                range
                                name="sale_at"
                                locale={gregorian_pt}
                                format="MM/DD/YYYY"
                                // style={{ height: '3.5rem', width: '100%', fontSize: '1rem', fontWeight: '700' }}
                                // containerStyle={{ width: '100%' }}
                                // placeholder="Coloque as datas referentes aos serviços aqui"
                                //minDate={new Date()}
                                value={values.sale_at}
                                dateSeparator=" a "
                                required
                                render={<PickerDate name="sale_at" label="Data da venda" />}
                            />
                        </Grid>
                        <Grid item xs>
                            <DatePicker
                                containerStyle={{ width: '100%' }}
                                onChange={e => setFieldValue('deliver_date', e)}
                                type="date"
                                range
                                name="deliver_date"
                                locale={gregorian_pt}
                                format="MM/DD/YYYY"
                                // style={{ height: '3.5rem', width: '100%', fontSize: '1rem', fontWeight: '700' }}
                                // containerStyle={{ width: '100%' }}
                                //minDate={new Date()}
                                value={values.delivery_date}
                                dateSeparator=" a "
                                required
                                render={<PickerDate name="deliver_date" label="Data de entrega" />}
                            />
                        </Grid>

                        <Grid item xs>
                            <SelectCustomer name="client_id" onChange={setFieldValue} value={Number(values.client_id)} addCustomer={false} />
                        </Grid>

                        <Grid item xs>
                            <Button variant="contained" onClick={handleSubmit}>
                                Aplicar filtros
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Drawer>
            <Container fixed>
                <Typography variant="h5" component="h2" my={3}>
                    Vendas
                </Typography>

                <Grid container direction="row" alignItems="center" spacing={2} sx={{ marginBottom: 2 }}>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={e => setDialogNewOrder(true)}>
                                Iniciar nova venda
                            </Button>
                            <Button variant="contained" onClick={e => setFilterView(true)}>
                                Filtros
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
                <DataGrid autoHeight loading={isLoading} local rows={data || []} columns={columns} />
                <Dialog
                    open={dialogNewOrder}
                    onClose={e => {
                        setDialogNewOrder(null);
                    }}
                    maxWidth="md"
                    fullWidth
                >
                    <NewOrder setViewDialog={setViewDialog} mutate={mutate} setDialogNewOrder={setDialogNewOrder}/>
                </Dialog>
                <Dialog
                    open={Boolean(viewDialog)}
                    onClose={e => {
                        setViewDialog(null);
                        mutate();
                    }}
                    maxWidth="md"
                    fullWidth
                >
                    <SaleDialog order_id={viewDialog} setViewDialog={setViewDialog} setDialogNewOrder={setDialogNewOrder} mutate={mutate} />
                </Dialog>
            </Container>
        </>
    );
};
