import React, { createContext, useContext, useEffect, useState } from 'react';
 
const AppContext = createContext({});

export const AppProvider = props => {
    const [loading, setLoading] = useState(false);
    const [isOffline, setOnline] = useState(false);

    const [orderId, setOrderId] = useState();

    const network = e => {
        const { type, returnValue } = e;
        if (type === 'offline') {
            setOnline(true);
        }

        if (type === 'online') {
            setOnline(false);
        }
    };

    useEffect(() => {
        const online = window.addEventListener('online', network);
        const offline = window.addEventListener('offline', network);

        return () => {
            window.removeEventListener('online', network);
            window.removeEventListener('offline', network);
        };
    });

    return (
        <AppContext.Provider
            value={{
                loading,
                setLoading,
                isOffline,
                // openMenu,
                // setOpenMenu,
                // openFilter,
                // setOpenFilter,
                // filter,
                // setFilter,
                // resultFilter,
                // setFilterResult,
                orderId,
                setOrderId,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};

export const useApp = () => {
    const context = useContext(AppContext);
    if (!context) throw 'useApp context inside corret provider';

    return context;
};
