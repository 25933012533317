import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useFetch } from '../hooks/useFetch';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import IconAdd from '@mui/icons-material/AddCircleOutlineSharp';
import server from '../global/server';
import useHandleErrors from '../hooks/useHandleErrors';
import { LoadingButton } from '@mui/lab';
import CustomerForm from '../modules/Customers/components/CustomerForm';

export default ({ onChange, name, value, addCustomer=true, ...props }) => {
    const [opened, setOpened] = useState(false);
    const [errs, setErrs] = useState();
    useHandleErrors(errs);

    const { data, isLoading, mutate } = useFetch('/customer');
    const options = data?.map(item => ({ label: item.name, id: item.id })) || [];

    const handleSelect = (e, data) => {
        onChange(name, data?.id || '');
    };

    const { values, isSubmitting, errors, setFieldValue, handleChange, handleSubmit, handleReset } = useFormik({
        initialValues: {
            name: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                const { data } = await server.post('/customer', values);
                await mutate();
                console.log(data.data);
                handleSelect(null, data.data);
                setOpened(false);
                setSubmitting(false);
            } catch (error) {
                setSubmitting(false);
                setErrs(error);
            }
        },
    });

    return (
        <>
            <Autocomplete
                options={options}
                onChange={handleSelect}
                value={options.filter(item => item.id === value)[0] || null}
                isOptionEqualToValue={props => props.id === value}
                getOptionLabel={ e=>e.label}
                loading={isLoading}
                id="select-customer"
                fullWidth
                renderInput={params => <TextField {...params} label="Clientes" />}
            />
            {addCustomer && <Button size="small" sx={{ alignSelf: 'flex-end' }} onClick={e => setOpened(true)}>
                Adicionar novo cliente
            </Button>}
            <Dialog
                open={opened}
                maxWidth="xs"
                fullWidth
                disableEscapeKeyDown={isSubmitting}
                onClose={e => {
                    setOpened(false);
                    handleReset();
                }}
            >
                <DialogTitle>Adicionar cliente</DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <CustomerForm values={values} handleChange={handleChange} setFieldValue={setFieldValue} errors={errors} />
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isSubmitting} onClick={e => setOpened(false)}>
                            Cancelar
                        </Button>
                        <LoadingButton loading={isSubmitting} type="submit" variant="contained">
                            Salvar
                        </LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};
