import { Navigate, Outlet} from 'react-router-dom';
import { useAuth } from '../modules/Auth/context/AuthContext';
import Container from './Container';

export const ProtectedLayout = () => {
    const { logged } = useAuth();
    // if (!logged) {
    //     return <Navigate to="/" />;
    // }
    return (
        <Container>
            <Outlet />
        </Container>
    );
};
