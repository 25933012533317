import { Alert, Button, Collapse, DialogActions, DialogContent, DialogTitle, Divider, IconButton, LinearProgress, Stack, Tab, Tabs } from '@mui/material';
import styled from 'styled-components';
import SalesProducts from './SalesProducts';
import { LoadingButton } from '@mui/lab';
import SalesClose from './SalesClose';
import { useFormik } from 'formik';
import server from '../../../global/server';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import useHandleErrors from '../../../hooks/useHandleErrors';
import { useFetch } from '../../../hooks/useFetch';
import SalesForm from './SalesForm';
import SalesOrder from './SalesOrder';
import { Close } from '@mui/icons-material';

export default ({ order_id, setViewDialog }) => {
    const [tabActive, setTabActive] = useState(0);
    const [errs, setErrs] = useState();
    const { enqueueSnackbar } = useSnackbar();
    useHandleErrors(errs);
    
    const { data, mutate, error, isLoading } = useFetch('/order', { params: { order_id } } );
    
    const { handleChange, handleSubmit, handleReset, setValues, setFieldValue, values, errors, isSubmitting } = useFormik({
        initialValues: {
            client_id: '',
            discount: '0',
            payment_mode: '',
            delivery_date: '',
            total: '0',
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true);
                values.discount = Number(values.discount);

                if (values.id) {
                    const { data } = await server.put(`/order/${values.id}`, values);
                    enqueueSnackbar('Venda atualizada');
                     
                } else {
                    const { data } = await server.post('/order', values);
                    enqueueSnackbar('Venda iniciada');
                    setValues({ ...data.data }, false);
                     
                }

                setSubmitting(false);
            } catch (error) {
                setSubmitting(false);
                setErrs(error);
            }
        },
    });

    const [isDownloaded, setDownloaded] = useState(false);

    const downloadPDFOrder = async () => {
        try {
            setDownloaded(true);
            const { data } = await server.get(`/report/${values.id}/download`, {
                headers: {
                    'Content-Type': 'application/pdf',
                },
                responseType: 'blob',
                timeout: 10000,
            });

            const link = document.createElement('a');
            const url = window.URL.createObjectURL(new Blob([data]));
            link.href = url;
            link.setAttribute('download', `Pedido #${values.id}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            setDownloaded(false);
        } catch (error) {
            setDownloaded(false);
            console.log(error.response);
        }
    };


    useEffect(() => {
        if (data && order_id) {
            setValues(data[0], false);
        }
    }, [data, order_id]);

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <DialogTitle>{Boolean(values.id) ? `Venda #${values.id}` : 'Nova venda'}</DialogTitle>
                <div>
                    <IconButton
                        onClick={e => {
                            setViewDialog();
                            setTabActive(0);
                            handleReset();
                        }}
                    >
                        <Close />
                    </IconButton>
                </div>
            </Stack>
            <Divider />
            {isLoading && <LinearProgress variant='indeterminate' />}
            <DialogContent>
                <Tabs value={tabActive} onChange={(e, index) => setTabActive(index)}>
                    <Tab label="Dados da venda" {...a11yProps(0)} />
                    {Boolean(values.id) && <Tab label="Produtos" {...a11yProps(1)} />}
                    {values.sales_status === 'CLOSED' && <Tab label="Pedido" {...a11yProps(2)} />}
                </Tabs>

                <TabPanel active={tabActive === 0}>
                    <SalesForm values={values} handleChange={handleChange} setFieldValue={setFieldValue} errors={errors} />
                </TabPanel>

                <TabPanel active={tabActive === 1}>
                    <SalesProducts order={values} mutate={mutate} />
                </TabPanel>
                {values.sales_status === 'CLOSED' && (
                    <TabPanel active={tabActive === 2}>
                        <SalesOrder values={values} handleChange={handleChange} setFieldValue={setFieldValue} errors={errors} mutate={mutate} />
                    </TabPanel>
                )}
            </DialogContent>
            <DialogActions>
                {/* <Button
                    onClick={e => {
                        setViewDialog();
                        setTabActive(0);
                        handleReset();
                    }}
                >
                    Cancelar
                </Button> */}
                <LoadingButton onClick={downloadPDFOrder} loading={isDownloaded}>Baixar pedido</LoadingButton>
                <LoadingButton onClick={handleSubmit} loading={isSubmitting}>Salvar</LoadingButton>
                {Boolean(values.id) && (
                    <>
                        <SalesClose order={values} mutate={mutate} />
                    </>
                )}
            </DialogActions>
        </>
    );
};

const TabPanel = styled.div`
    display: ${props => (props.active ? 'block' : 'none')};
`;
