import {
    Box,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp, AddCircle, DeleteForever } from '@mui/icons-material';
import SelectProduct from '../../../components/SelectProduct';
import { useFormik } from 'formik';
import server from '../../../global/server';
import ChooseProducts from './ChooseProducts';
import { enqueueSnackbar } from 'notistack';
// import RoadmapCosts from './RoadmapCosts';

const messures = {
    linear: 'cm',
    m2: 'm2',
    unidade: 'unids',
};

const messureValue = (value, type)=> {
    switch (type) {
        case 'm2':
            return value /10000
        case 'linear':
            return value     
        default:
            return value
    }
}
const Product = ({ row, handleEdit, switchRow, board, addChild, child, removeItem }) => {
    const [open, setOpen] = switchRow;
    return (
        <>
            <TableRow>
                <TableCell
                    sx={
                        child && {
                            position: 'relative',
                            '&::before': {
                                content: '" "',
                                position: 'absolute',
                                left: -24,
                                top: '40%',
                                width: 15,
                                height: 15,
                                background: '#ccc',
                                borderRadius: 50,
                            },
                        }
                    }
                >
                    {row.id}
                </TableCell>
                <TableCell>
                    {row.Product.product_name} {row.Product.board && `${row.width}X${row.height}`}
                </TableCell>
                {/* <TableCell>{row.Product.board.toString()}</TableCell> */}

                <TableCell>{row.sale_price || `-`}</TableCell>
                <TableCell>
                    {' '}
                    {messureValue(Number(row.qty), row.Product.sale_mode)} {messures[row.Product.sale_mode]}
                </TableCell>
                <TableCell> {Number(row.subtotal).toLocaleString()} </TableCell>
                <TableCell></TableCell>
                <TableCell>
                    <IconButton onClick={e => removeItem(row.id)}>
                        <DeleteForever />
                    </IconButton>
                </TableCell>
                {board && (
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(row.id === open ? null : row.id)}>
                            {open === row.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                    </TableCell>
                )}
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={8}>
                    <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                        <Box sx={{ paddingLeft: 2, marginLeft: 5, borderLeft: '1px solid #ccc' }}>
                            {row.Childs && <SaleListProducts products={row.Childs} board={false} handleEdit={handleEdit} removeItem={removeItem} />}
                        </Box>
                        <Stack sx={{ textAlign: 'center' }} py={2}>
                            <Button onClick={e => addChild(row.id)} startIcon={<AddCircle />}>
                                Adicionar composiçao
                            </Button>
                        </Stack>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const SaleListProducts = ({ products, handleEdit, board = true, removeItem, addChild }) => {
    const switchRow = useState();
    // const [costView, setCostView] = useState();

    return (
        <>
            <Table size={'small'} sx={[!board && { background: '#eee' }]}>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Produto</TableCell>
                        {/* <TableCell>Tipo</TableCell> */}
                        <TableCell>Valor</TableCell>
                        <TableCell>Quantidade</TableCell>
                        <TableCell>Subtotal</TableCell>
                        <TableCell></TableCell>
                        {board && <TableCell></TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {products?.map(item => (
                        <Product
                            key={item.id}
                            row={item}
                            handleEdit={handleEdit}
                            switchRow={switchRow}
                            board={item.Product.board}
                            child={Boolean(item.child_from)}
                            removeItem={removeItem}
                            addChild={addChild}
                        />
                    ))}
                </TableBody>
            </Table>
        </>
    );
};

export default ({ order, handleEdit, mutate }) => {
    const [viewDialog, setViewDialog] = useState(false);
    const { values, handleChange, setFieldValue, handleSubmit, handleReset, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            product_id: '',
            qty: '',
            width: '',
            height: '',
            child_from: '',
            board: false,
            printed: false,
            frame: false,
        },
        onSubmit: async (values, { isSubmitting }) => {
            try {
                await server.post('order/item', {
                    order_id: order.id,
                    ...values,
                });
                await mutate();
                handleReset();
                enqueueSnackbar('Item adicionado');
                setViewDialog(false);
            } catch (error) {
                console.log(error);
            }
        },
    });
 

    const selectedProduct = e => {
        setFieldValue('product_id', e.id);
        handleSubmit();
    };

    const addChild = child_from => {
        setViewDialog(true);
        setFieldValue('child_from', child_from);
    };

    const removeItem = async id => {
        try {
            await server.delete('order/item', { data: { id } });
            await mutate();
            enqueueSnackbar('Item removido');
        } catch (error) {
            setSubmitting(false);
        }
    };

    return (
        <>
            <Stack direction="row" justifyContent="flex-end" py={1}>
                <Button onClick={e => setViewDialog(true)}>Adicionar produto</Button>
                <form>
                    <Dialog maxWidth="sm" fullWidth open={viewDialog} onClose={e => setViewDialog(false)}>
                        <DialogTitle>Adicionar produto</DialogTitle>
                        <Divider />
                        <DialogContent>
                            <ChooseProducts
                                values={values}
                                isBoard={values.board}
                                isChild={values.child_from}
                                setFieldValue={setFieldValue}
                                handleChange={handleChange}
                                selectedProduct={selectedProduct}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={e => setViewDialog(false)}>Cancelar</Button>
                            <Button onClick={handleSubmit} variant="contained">
                                Adicionar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </form>
            </Stack>
            <SaleListProducts
                products={order.OrderProduct}
                handleChild={addChild}
                handleEdit={handleEdit}
                removeItem={removeItem}
                addChild={addChild}
            />
        </>
    );
};
