import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import SelectCategory from '../../../components/SelectCategory';

export default ({ handleChange, setFieldValue, values, errors }) => {
    return (
        <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
                <TextField
                    required
                    helperText={errors.name}
                    inputProps={{
                        maxLength: 30,
                    }}
                    onChange={handleChange}
                    value={values.name}
                    fullWidth
                    name="name"
                    label="Nome do cliente"
                    error={Boolean(errors.name)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    helperText={errors.document}
                    inputProps={{
                        maxLength: 30,
                    }}
                    onChange={handleChange}
                    value={values.document}
                    fullWidth
                    name="document"
                    label="Documento (CPF ou CPNJ)"
                    InputProps={{ maxLength: 14 }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    helperText={errors.phone}
                    inputProps={{
                        maxLength: 30,
                    }}
                    onChange={handleChange}
                    value={values.phone}
                    fullWidth
                    name="phone"
                    label="Telefone (DDD + Telefone)"
                    InputProps={{ maxLength: 11 }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    helperText={errors.email}
                    inputProps={{
                        maxLength: 30,
                    }}
                    onChange={handleChange}
                    value={values.email}
                    fullWidth
                    name="email"
                    type='email'
                    label="E-mail"
                    InputProps={{ maxLength: 11 }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    helperText={errors.zipcode}
                    inputProps={{
                        maxLength: 30,
                    }}
                    onChange={handleChange}
                    value={values.zipcode}
                    fullWidth
                    name="zipcode"
                    label="CEP"
                    InputProps={{ maxLength: 7 }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    helperText={errors.complement}
                    inputProps={{
                        maxLength: 30,
                    }}
                    onChange={handleChange}
                    value={values.complement}
                    fullWidth
                    name="complement"
                    label="Complemento"
                />
            </Grid>
        </Grid>
    );
};
