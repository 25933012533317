import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppProvider } from './context/AppContext';
import { AuthProvider } from './modules/Auth/context/AuthContext';
import Routes from './routes';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ConfirmProvider } from 'material-ui-confirm';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ptBR } from '@mui/x-data-grid';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { LocalizationProvider } from '@mui/x-date-pickers';

const myTheme = createTheme({
    overrides: {
        MUIRichTextEditor: {
            root: {
                border: '1px solid #ccc',
                borderRadius: '4px',
            },
            editor: {
                borderTop: '1px solid #ccc',
                paddingLeft: '30px',
            },
            placeHolder: {
                fontSize: '16px',
                paddingLeft: '20px',
                position: 'inherit',
            },
        },
    },
    ptBR,
});

function App() {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={myTheme}>
                <BrowserRouter basename="/">
                    <SnackbarProvider>
                        <ConfirmProvider>
                            <AuthProvider>
                                <AppProvider>
                                    <Routes />
                                </AppProvider>
                            </AuthProvider>
                        </ConfirmProvider>
                    </SnackbarProvider>
                </BrowserRouter>
            </ThemeProvider>
        </LocalizationProvider>
    );
}

export default App;
