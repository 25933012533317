import { LoadingButton } from '@mui/lab';
import {
    Button,
    Chip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import server from '../../../global/server';
import { useFetch } from '../../../hooks/useFetch';
import useHandleErrors from '../../../hooks/useHandleErrors';
import CustomerForm from '../components/CustomerForm';
import { Close } from '@mui/icons-material';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
});

export default () => {
    const navigation = useNavigate();
    const { data, mutate, error, isFetching } = useFetch('/customer');
    const [errs, setErrs] = useState();
    const [viewDialog, setViewDialog] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    useHandleErrors(errs);

    const { handleChange, handleSubmit, handleReset, setValues, setFieldValue, values, errors, isSubmitting, setSubmitting } = useFormik({
        initialValues: {
            name: '',
            phone: '',
            zipcode: '',
            address: '',
            complement: '',
            email: '',
            document: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                console.log(values);
                setSubmitting(true);

                if (values.id) {
                    const { data } = await server.put('/customer', values);
                    enqueueSnackbar('Cliente alterado');
                    setViewDialog(false);
                } else {
                    const { data } = await server.post('/customer', values);
                    enqueueSnackbar('Cliente adicionado');
                }
                setSubmitting(false);
                await mutate();
                handleReset();
            } catch (error) {
                setSubmitting(false);
                setErrs(error);
            }
        },
    });

    const handleDelete = async id => {
        try {
            await server.delete('/customer', { data: { id } });
            await mutate();
            handleReset();
            enqueueSnackbar('Cliente alterado');
            setViewDialog(false);
        } catch (error) {
            setSubmitting(false);
            setErrs(error);
        }
    };

    const columns = useMemo(() => [
        { field: 'id', headerName: 'ID', width: 50 },
        {
            field: 'name',
            headerName: 'Nome',
            flex: 1,
            minWidth: 250,
        },
        {
            field: 'document',
            headerName: 'Document',
            flex: 1,
        },
        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
        },
        {
            field: 'phone',
            headerName: 'Telefone',
            flex: 1,
        },
        {
            field: 'zipcode',
            headerName: 'CEP',
            flex: 1,
        },
        {
            field: 'actions',
            type: 'actions',
            renderCell: ({ row }) => (
                <Button
                    onClick={e => {
                        setValues(row, false);
                        setViewDialog(true);
                    }}
                >
                    Alterar
                </Button>
            ),
        },
    ]);

    return (
        <Container fixed>
            <Typography variant="h5" component="h2" my={3}>
                Clientes
            </Typography>

            <Grid container direction="row" alignItems="center" spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item xs={12} sx={{ textAlign: 'right' }}>
                    <Button variant="contained" onClick={e => setViewDialog(true)}>
                        Adicionar cliente
                    </Button>
                </Grid>
            </Grid>
            <DataGrid autoHeight loading={isFetching} rows={data || []} columns={columns} />
            <Dialog
                components="form"
                open={viewDialog}
                onClose={e => {
                    handleReset();
                    setViewDialog(false);
                }}
            >
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <DialogTitle>Novo cliente</DialogTitle>
                    <IconButton
                        onClick={() => {
                            setViewDialog(false);
                            handleReset();
                        }}
                    >
                        <Close />
                    </IconButton>
                </Stack>
                <Divider />
                <DialogContent>
                    <CustomerForm values={values} handleChange={handleChange} setFieldValue={setFieldValue} errors={errors} />
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button color="error" onClick={e => window.confirm('Você tem certeza?') && handleDelete(values.id)}>
                        Excluir
                    </Button>
                    <Button onClick={e => setViewDialog(false)}>Cancelar</Button>
                    <LoadingButton onClick={handleSubmit}>Salvar</LoadingButton>
                </DialogActions>
            </Dialog>
        </Container>
    );
};
