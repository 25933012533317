import { LoadingButton } from '@mui/lab';
import { Button, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Stack, TextField, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import server from '../../../global/server';
import { useFetch } from '../../../hooks/useFetch';
import useHandleErrors from '../../../hooks/useHandleErrors';
import ProductForm from '../components/ProductForm';
import SearchField from '../components/SearchField';

export default () => {
    const navigation = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const { data, mutate, error, isFetching } = useFetch('/product', { params: searchParams });
    const [errs, setErrs] = useState();
    const [viewDialog, setViewDialog] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    useHandleErrors(errs);

    const { handleChange, handleSubmit, handleReset, setValues, setFieldValue, values, errors, isSubmitting } = useFormik({
        initialValues: {
            product_name: '',
            category_id: '',
            sale_mode: '',
            bleed: '0',
            allow_negative_stock: '',
            cost_price: '',
            sale_price: '',
            stock: '',
            printed: false,
            board: false,
            frame: false,
            heightFrame: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                console.log(values);
                setSubmitting(true);
                values.sale_price = Number(values.sale_price);
                values.cost_price = Number(values.cost_price);
                values.stock = Number(values.stock);
                values.bleed = Number(values.bleed);

                if (values.id) {
                    const { data } = await server.put(`/product/${values.id}`, values);
                    enqueueSnackbar('Produto alterado');
                    setViewDialog(false);
                } else {
                    const { data } = await server.post(`/product`, values);
                    enqueueSnackbar('Produto adicionado');
                }
                setSubmitting(false);
                await mutate();
                handleReset();
            } catch (error) {
                console.log(error);
                setSubmitting(false);
                //setErrs(error);
            }
        },
    });

    const columns = useMemo(() => [
        { field: 'id', headerName: 'ID', width: 50 },
        {
            field: 'product_name',
            headerName: 'Nome',
            flex: 1,
            minWidth: 250,
            // valueGetter: params => size(params.value)
        },
        {
            field: 'bleed',
            headerName: 'Sangria',
            flex: 1,
            // valueGetter: params => size(params.value)
        },
        {
            field: 'sale_mode',
            headerName: 'Modo de venda',
            flex: 1,
            // valueGetter: params => size(params.value)
        },
        {
            field: 'cost_price',
            headerName: 'Preço de custo',
            flex: 1,
            // valueGetter: params => size(params.value)
        },
        {
            field: 'sale_price',
            headerName: 'Preço de venda',
            flex: 1,
            // valueGetter: params => size(params.value)
        },
        {
            field: 'Category',
            headerName: 'Categoria',
            flex: 1,
            valueGetter: ({ value }) => value.name,
            renderCell: ({ value }) => value,
        },
        {
            field: 'stock',
            headerName: 'Estoque',
            flex: 1,
            // valueGetter: params => size(params.value)
        },

        // {
        //     field: 'status',
        //     headerName: 'Status',
        //     renderCell: params => (params.value ? <Chip color="success" label="Ativo" /> : <Chip color="error" label="Inativo" />),
        // },

        {
            field: 'actions',
            //headerName: 'actions',
            type: 'actions',
            renderCell: ({ row }) => (
                <Button
                    onClick={e => {
                        setValues(row, false);
                        setViewDialog(true);
                    }}
                >
                    Alterar
                </Button>
            ),
        },
    ]);

    const [submitting, setSubmitting] = useState(false);

    // const excluir = async id => {
    //     try {
    //         setSubmitting(true);
    //         await server.delete('/points', { data: { id } });
    //         enqueueSnackbar('Item removido');
    //         setSubmitting(false);
    //         mutate();
    //         handleReset();
    //         setViewDialog(false);
    //     } catch (error) {
    //         setSubmitting(false);
    //         setErrs(error);
    //     }
    // };

    return (
        <Container fixed>
            <Typography variant="h5" component="h2" my={3}>
                Produtos
            </Typography>

            <Grid container direction="row" alignItems="center" spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" columnGap={2}>
                        <Stack>
                            <SearchField />
                        </Stack>
                        <Stack>
                            <Button variant="contained" onClick={e => setViewDialog(true)}>
                                Adicionar produto
                            </Button>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
            <DataGrid autoHeight loading={isFetching} rows={data || []} columns={columns} />
            <Dialog
                open={viewDialog}
                onClose={e => {
                    handleReset();
                    setViewDialog(false);
                }}
            >
                <DialogTitle>{values.id ? `Alterando: ${values.product_name}` : 'Novo produto'}</DialogTitle>
                <DialogContent>
                    <ProductForm values={values} handleChange={handleChange} setFieldValue={setFieldValue} errors={errors} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={e => setViewDialog(false)}>Cancelar</Button>
                    <LoadingButton onClick={handleSubmit}>Salvar</LoadingButton>
                </DialogActions>
            </Dialog>
        </Container>
    );
};
