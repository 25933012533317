import { Button, Checkbox, Dialog, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import SelectCategory from '../../../components/SelectCategory';
import StockUpdate from './StockUpdate';
import { useState } from 'react';

export default ({ handleChange, setFieldValue, values, errors, mutate }) => {

    const [openStock, setOpenStock] = useState(false)
    return (
        <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
                <TextField
                    required
                    helperText={errors.product_name}
                    inputProps={{
                        maxLength: 30,
                    }}
                    onChange={handleChange}
                    value={values.product_name}
                    fullWidth
                    name="product_name"
                    label="Nome do produto"
                />
            </Grid>
            <Grid item xs={12}>
                <SelectCategory
                    helperText={errors.category_id}
                    inputProps={{
                        maxLength: 100,
                    }}
                    rows={2}
                    onChange={setFieldValue}
                    value={values.category_id}
                    fullWidth
                    name="category_id"
                    label="Categoria"
                />
            </Grid>

            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="selec-sale-mode">Modo de venda</InputLabel>
                    <Select
                        labelId="selec-sale-mode"
                        id="sale-mode"
                        value={values.sale_mode}
                        label="Modo de venda"
                        name="sale_mode"
                        onChange={handleChange}
                    >
                        <MenuItem value="m2">M2</MenuItem>
                        <MenuItem value="linear">Linear</MenuItem>
                        <MenuItem value="unidade">Unidade</MenuItem>
                    </Select>
                    <FormHelperText>{errors.sale_mode}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={values.board} name="board" inputProps={{ 'aria-label': 'controlled' }} onChange={handleChange} />}
                        label="Quadro"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={values.frame} name="frame" inputProps={{ 'aria-label': 'controlled' }} onChange={handleChange} />}
                        label="Moldura"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox checked={values.printed} name="printed" inputProps={{ 'aria-label': 'controlled' }} onChange={handleChange} />
                        }
                        label="Impressão"
                    />
                </FormGroup>
            </Grid>
            {!values.board && (
                <>
                    {values.frame && (
                        <Grid item xs={6}>
                            <TextField
                                required
                                helperText={errors.heightFrame}
                                onChange={handleChange}
                                value={values.heightFrame}
                                fullWidth
                                name="heightFrame"
                                label="Largura da moldura (cm)"
                            />
                        </Grid>
                    )}
                    <Grid item xs={6}>
                        <TextField helperText={errors.bleed} onChange={handleChange} value={values.bleed} fullWidth name="bleed" label="Sangria" />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            required
                            helperText={errors.cost_price}
                            onChange={handleChange}
                            value={values.cost_price}
                            fullWidth
                            name="cost_price"
                            label="Preço de custo"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            required
                            helperText={errors.sale_price}
                            onChange={handleChange}
                            value={values.sale_price}
                            fullWidth
                            name="sale_price"
                            label="Preço de venda"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            required
                            helperText={errors.stock}
                            onChange={handleChange}
                            value={values.stock}
                            fullWidth
                            name="stock"
                            label="Estoque"
                        />
                        <Button onClick={e=>setOpenStock(true)}>Atualizar estoque</Button>
                    </Grid>

                    <Dialog open={openStock} fullWidth maxWidth="xs" onClose={e=>setOpenStock(false)} >
                        <DialogTitle>Atualizar estoque</DialogTitle>
                        <StockUpdate data={values} mutate={mutate} />
                    </Dialog>
                </>
            )}
        </Grid>
    );
};
