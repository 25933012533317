import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useFetch } from '../hooks/useFetch';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import IconAdd from '@mui/icons-material/AddCircleOutlineSharp';
import server from '../global/server';
import useHandleErrors from '../hooks/useHandleErrors';
import { LoadingButton } from '@mui/lab';

export default ({ onChange, name, value, ...props }) => {
    const [opened, setOpened] = useState(false);
    const [errs, setErrs] = useState();
    useHandleErrors(errs);

    const { data, isLoading, mutate } = useFetch('/category');
    const options = data?.map(item => ({ label: item.name, id: item.id })) || [];

    const handleSelect = (e, data) => {
        onChange(name, data?.id || '');
    };

    const { values, isSubmitting, handleChange, handleSubmit, handleReset } = useFormik({
        initialValues: {
            name: '',
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                setSubmitting(true);
                const { data } = await server.post('/category', values);
                await mutate();
                console.log(data.data)
                handleSelect(null, data.data);
                setOpened(false);
                setSubmitting(false);
            } catch (error) {
                setSubmitting(false);
                setErrs(error);
            }
        },
    });

    return (
        <>
            <Autocomplete
                options={options}
                onChange={handleSelect}
                value={options.filter(item => item.id === value)[0] || null}
                isOptionEqualToValue={props => props.id === value}
                loading={isLoading}
                id="select-category"
                fullWidth
                renderInput={params => <TextField {...params} label="Categoria" />}
            />
            <Button size="small" sx={{ alignSelf: 'flex-end' }} onClick={e => setOpened(true)}>
                Adicionar nova categoria
            </Button>
            <Dialog
                open={opened}
                maxWidth="xs"
                fullWidth
                disableEscapeKeyDown={isSubmitting}
                onClose={e => {
                    setOpened(false);
                    handleReset();
                }}
            >
                <DialogTitle>Adicionar categoria</DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <TextField value={values.name} onChange={handleChange} fullWidth name="name" />
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isSubmitting} onClick={e => setOpened(false)}>
                            Cancelar
                        </Button>
                        <LoadingButton loading={isSubmitting} type="submit" variant="contained">
                            Salvar
                        </LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};
