import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import server from '../../../global/server';
import useHandleErrors from '../../../hooks/useHandleErrors';
import { useSnackbar } from 'notistack';

export default ({order, disabled, mutate}) => {
    const [errs, setErrs] = useState();
    useHandleErrors(errs);

    const { enqueueSnackbar } = useSnackbar();

    const { isSubmitting, handleSubmit } = useFormik({
        initialValues: {},
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                await server.patch('/order',{ id: order.id });
                await mutate();
                enqueueSnackbar('Venda fechada');
                setSubmitting(false);
            } catch (error) {
                setErrs(error);
                setSubmitting(false);
            }
        },
    });
    return (
        <LoadingButton disabled={order.sales_status === 'CLOSED'} loading={isSubmitting} onClick={handleSubmit}>
            Fechar venda
        </LoadingButton>
    );
};
