import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/AddCircle';
import ExitIcon from '@mui/icons-material/ExitToApp';
import ConfigIcon from '@mui/icons-material/Settings';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import OrdersIcon from '@mui/icons-material/FileOpen';
import UsersIcon from '@mui/icons-material/Person';
import ProfessionalIcon from '@mui/icons-material/Contacts';
import SettingsIcon from '@mui/icons-material/Settings';
import CustomerIcon from '@mui/icons-material/RecentActors';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InventoryIcon from '@mui/icons-material/Inventory';
import SalesIcon from '@mui/icons-material/MonetizationOn';
import MenuIcon from '@mui/icons-material/Menu';

import { useApp } from '../context/AppContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../modules/Auth/context/AuthContext';
import { IconButton, LinearProgress, Stack, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useTheme } from '@emotion/react';

const AppBarAdmin = ({ setOpenMenu, filter, setOpenFilter }) => {
    const { LogOut, user } = useAuth();
    const { pathname } = useLocation();
    const navigation = useNavigate();

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <AppBar position="sticky">
            <Toolbar>
                {mobile && (
                    <IconButton onClick={() => setOpenMenu(true)} size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
                        <MenuIcon />
                    </IconButton>
                )}

                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Bonavides Sys <small style={{ fontSize: 10 }}>(1.0.250823)</small>
                </Typography>

                {!mobile && (
                    <>
                        <Button
                            onClick={() => navigation('dashboard')}
                            variant="text"
                            sx={{ color: '#fff', marginRight: 1 }}
                            startIcon={<DashboardIcon />}
                        >
                            Dashboard
                        </Button>
                        <Button onClick={() => navigation('vendas')} variant="text" sx={{ color: '#fff', marginRight: 1 }} startIcon={<SalesIcon />}>
                            Vendas
                        </Button>
                        <Button
                            onClick={() => navigation('produtos')}
                            variant="text"
                            sx={{ color: '#fff', marginRight: 1 }}
                            startIcon={<InventoryIcon />}
                        >
                            Produtos
                        </Button>
                        <Button
                            onClick={() => navigation('clientes')}
                            variant="text"
                            sx={{ color: '#fff', marginRight: 1 }}
                            startIcon={<CustomerIcon />}
                        >
                            Clientes
                        </Button>
                        <Button
                            onClick={() => navigation('usuarios')}
                            variant="text"
                            sx={{ color: '#fff', marginRight: 1 }}
                            startIcon={<UsersIcon />}
                        >
                            Usuários
                        </Button>
                        <Button variant="text" sx={{ color: '#fff' }} onClick={() => LogOut()} startIcon={<ExitIcon />}>
                            Sair
                        </Button>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default props => {
    const { filter, setOpenFilter, loading } = useApp();
    const { LogOut, user } = useAuth();
    const navigation = useNavigate();
    const [openMenu, setOpenMenu] = useState(false);

    return (
        <>
            <AppBarAdmin setOpenMenu={setOpenMenu} filter={filter} setOpenFilter={setOpenFilter} />
            {loading && <LinearProgress variant="indeterminate" />}
            <Drawer anchor="left" open={openMenu} onClose={() => setOpenMenu(false)}>
                <Box sx={{ width: 280 }} role="presentation" onClick={() => setOpenMenu(false)}>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigation('/dashboard')}>
                                <ListItemIcon>
                                    <DashboardIcon />
                                </ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigation('/vendas')}>
                                <ListItemIcon>
                                    <InventoryIcon />
                                </ListItemIcon>
                                <ListItemText primary="Vendas" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigation('/produtos')}>
                                <ListItemIcon>
                                    <SalesIcon />
                                </ListItemIcon>
                                <ListItemText primary="Produtos" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigation('/clientes')}>
                                <ListItemIcon>
                                    <CustomerIcon />
                                </ListItemIcon>
                                <ListItemText primary="Clientes" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigation('/usuarios')}>
                                <ListItemIcon>
                                    <UsersIcon />
                                </ListItemIcon>
                                <ListItemText primary="Usuários" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => LogOut()}>
                                <ListItemIcon>
                                    <ExitIcon />{' '}
                                </ListItemIcon>
                                <ListItemText primary="Sair" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Divider />
                </Box>
            </Drawer>
        </>
    );
};
