import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import server from '../../../global/server';

const AuthContext = createContext({});

export const AuthProvider = props => {
    const navigation = useNavigate();

    const [token, setToken] = useState();
    const [user, setUser] = useState({});

    const verifySession = async () => {
        const token = sessionStorage.getItem('token');

        if (token) {
            const sessionUser = sessionStorage.getItem('user');
            server.defaults.headers['Authorization'] = `Bearer ${token}`;
            setToken(token);
            const user = JSON.parse(sessionUser);
            setUser(user);

            // navigation('/dashboard');
        } else {
            delete server.defaults.headers.Authorization;
            setToken();
            navigation('/');
        }
    };

    useEffect(() => {
        verifySession();
    }, []);

    const LogOut = () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user');
        verifySession();
    };

    return <AuthContext.Provider value={{ logged: Boolean(token), user, LogOut, verifySession, setUser }}>{props.children}</AuthContext.Provider>;
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) throw 'Use Auth Context inside Auth Provider';

    return context;
};
