import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import SelectCategory from '../../../components/SelectCategory';
import SelectCustomer from '../../../components/SelectCustomer';
import { orderStatus } from '../../../global/helpers';

export default ({ handleChange, setFieldValue, values, errors }) => {
    return (
        <Grid container spacing={2} mt={1}>
            <Grid item xs>
                <FormControl fullWidth>
                    <InputLabel id="edducation">Status do Pedido</InputLabel>
                    <Select
                        labelId="edducation"
                        id="order_status"
                        name="order_status"
                        value={values.order_status}
                        label="Status do Pedido"
                        onChange={handleChange}
                    >
                        <MenuItem value="">TODOS</MenuItem>

                        <MenuItem value="WAITING">{orderStatus['WAITING']}</MenuItem>
                        <MenuItem value="IN_PROGRESS">{orderStatus['IN_PROGRESS']}</MenuItem>
                        <MenuItem value="FINISHED">{orderStatus['FINISHED']}</MenuItem>
                        <MenuItem value="CONCLUDED">{orderStatus['CONCLUDED']}</MenuItem>
                        <MenuItem value="ORDER_CANCELED">{orderStatus['ORDER_CANCELED']}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <TextField
                     
                    required
                    helperText={errors.delivery_date}
                    onChange={handleChange}
                    value={Boolean(values.delivery_date ) ? new Date(values.delivery_date).toISOString().split('T')[0] : null}
                    fullWidth
                    type="date"
                    name="delivery_date"
                    label="Data da entrega"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    multiline
                    helperText={errors.details}
                    onChange={handleChange}
                    value={values.details}
                    minRows={3}
                    fullWidth
                    name="details"
                    label="Observações"
                />
            </Grid>
        </Grid>
    );
};
