export const bytesToSize = bytes => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]})`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const orderStatus = {
    WAITING: 'AGUARDANDO',
    IN_PROGRESS: 'EM PROGRESSO',
    FINISHED: 'FINALIZADO',
    CONCLUDED: 'ENTREGUE',
    ORDER_CANCELED: 'CANCELADO',
};

export const saleStatus = {
    DRAFT: 'ABERTA',
    CLOSED: 'FECHADA',
    CANCELED: 'CANCELADO',
};

export const payments = {
    credit_card: 'Cartão de Crédito',
    pix: 'PIX',
    cash: 'Dinheiro',
    credit_card_installments: 'Cartão de Crédito - Parcelado',
    debit_card: 'Cartão de Débito',
};
