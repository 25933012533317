import { LoadingButton } from '@mui/lab';
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    Switch,
    TextField,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useFormik } from 'formik';
import { useMemo, useState } from 'react';
import server from '../../../global/server';
import { useFetch } from '../../../hooks/useFetch';
import useHandleErrors from '../../../hooks/useHandleErrors';
import HumanIcon from '@mui/icons-material/Person2';
import VehicleIcon from '@mui/icons-material/DirectionsCar';
import { useSnackbar } from 'notistack';
import { useAuth } from '../../Auth/context/AuthContext';

export default () => {
    const columns = useMemo(() => [
        { field: 'id', headerName: 'ID', width: 50 },
        {
            field: 'name',
            headerName: 'Nome',
            flex: 1,
            // valueGetter: params => size(params.value)
        },
        // {
        //     field: 'status',
        //     headerName: 'Status',
        //     renderCell: params => (params.value ? <Chip color="success" label="Ativo" /> : <Chip color="error" label="Inativo" />),
        // },
        {
            field: 'vehicle',
            headerName: 'Tipo',
            renderCell: params => (params.value ? <VehicleIcon /> : <HumanIcon />),
        },

        {
            field: 'actions',
            //headerName: 'actions',
            type: 'actions',
            renderCell: params => (
                <Button
                    onClick={e => {
                        setValues(params.row, false);
                        setViewDialog(true);
                    }}
                >
                    Alterar
                </Button>
            ),
        },
    ]);

    const { data, mutate, error, isFetching } = useFetch('/product');
    const { enqueueSnackbar } = useSnackbar();
    const [errs, setErrs] = useState();
    const [viewDialog, setViewDialog] = useState(false);
    useHandleErrors(errs);
    const { handleChange, handleSubmit, handleReset, setValues, values, errors } = useFormik({
        initialValues: {
            name: '',
            vehicle: false,
        },
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                if (values.id) {
                    await server.put('/product', values);
                    enqueueSnackbar('Produto alterado');
                } else {
                    await server.post('/product', values);
                    enqueueSnackbar('Produto adicionado');
                }
                setSubmitting(false);
                mutate();
                handleReset();
                setViewDialog(false);
            } catch (error) {
                setSubmitting(false);
                setErrs(error);
            }
        },
    });

    useHandleErrors(errs);

    const [submitting, setSubmitting] = useState(false);
    const excluir = async id => {
        try {
            setSubmitting(true);
            console.log(id);
            await server.delete('/product', { data: { id } });
            enqueueSnackbar('Item removido');
            setSubmitting(false);
            mutate();
            handleReset();
            setViewDialog(false);
        } catch (error) {
            setSubmitting(false);
            setErrs(error);
        }
    };

    

    return (
        <>
            <Grid container direction="row" alignItems="center" spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item xs={12} sx={{ textAlign: 'right' }}>
                    <Button variant="contained" onClick={e => setViewDialog(true)}>
                        Adicionar produto
                    </Button>
                </Grid>
            </Grid>
            <DataGrid autoHeight checkboxSelection loading={isFetching} rows={data || []} columns={columns} />
            <Dialog
                open={viewDialog}
                onClose={e => {
                    handleReset();
                    setViewDialog(false);
                }}
            >
                <DialogTitle>{Boolean(values.id) ? 'Editando produto' : 'Novo produto'}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12}>
                            <TextField helperText={errors.name} onChange={handleChange} value={values.name} fullWidth name="name" label="Nome" />
                        </Grid>

                        <Grid item xs={12}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch name="vehicle" onChange={handleChange} checked={values.vehicle} />}
                                    label="O produto é veículo?"
                                />
                                <FormHelperText>{errors.vehicle}</FormHelperText>
                            </FormGroup>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {Boolean(values.id) && (
                        <LoadingButton
                            loading={submitting}
                            color="error"
                            onClick={e => window.confirm('Você confirma a exclusão do item?') && excluir(values.id)}
                        >
                            Excluir
                        </LoadingButton>
                    )}
                    <Button onClick={e => setViewDialog(false)}>Cancelar</Button>
                    <LoadingButton onClick={handleSubmit}>Salvar</LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
