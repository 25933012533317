import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedLayout } from '../components/ProtectedLayout';
import Login from '../modules/Auth/screens/Login';
import Dashboard from '../modules/Dashboard';
import Settings from '../modules/Settings/screens';
import ProductsList from '../modules/Products/screens/ProductsList';
import Products from '../modules/Products';
import Customers from '../modules/Customers';
import CustomersList from '../modules/Customers/screens/CustomersList';
import Sales from '../modules/Sales';
import SalesList from '../modules/Sales/screens/SalesList';
import Users from '../modules/Users';
import UsersList from '../modules/Users/screens/UsersList';

export default () => {
    return (
        <Routes>
            <Route index path="/" element={<Login />} />

            <Route element={<ProtectedLayout />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="produtos" element={<Products />}>
                    <Route index element={<ProductsList />} />
                </Route>
                <Route path="clientes" element={<Customers />}>
                    <Route index element={<CustomersList />} />
                </Route>
                <Route path="vendas" element={<Sales />}>
                    <Route index element={<SalesList />} />
                </Route>
                <Route path="usuarios" element={<Users />}>
                    <Route index element={<UsersList />} />
                </Route>

                <Route path="configuracoes" element={<Settings />} />
            </Route>
        </Routes>
    );
};
