import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useFetch } from '../hooks/useFetch';

export default ({ onChange, name, value, sale, exclude, ...props }) => {
    const { data, isLoading } = useFetch('/product', {params:{exclude}});

    const options = data?.map(item => ({ label: item.product_name, id: item.id, board: item.board, frame: item.frame, printed: item.printed })) || [];

    const handleChange = (e, data) => {
        if (sale) {
            onChange(data);
        } else {
            onChange(name, data?.id || '');
        }
    };
    return (
        <>
            <Autocomplete
                options={options}
                onChange={handleChange}
                value={options.filter(item => item.id === value)[0] || null}
                isOptionEqualToValue={props => props.id === value}
                loading={isLoading}
                id="select-product"
                fullWidth
                renderInput={params => <TextField {...params} label="Produto" />}
            />
        </>
    );
};
